import { useEffect, useState } from "react";
import "../styles/singlepost.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import PostItem from "../components/post_item";
import newconnapplicationform from "../assets/docs/newconnectionform.pdf";
import cis from "../assets/imgs/cis.png";
import pe from "../assets/imgs/publicparticipation.jpeg";
import waterinterruptiongatamaiyu from "../assets/imgs/waterinterruptiongatamaiyu.jpg";
import waterrestoredgatamaiyu from "../assets/imgs/waterrestoredgatamaiyu.jpg";
import PostItems from "../components/posts/postItems";

export default function SinglePost (props) {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { title, date, image1, image2, details } = state || {};
    const [toggleMenu, setToggleMenu] = useState(false);
   const [toggleQuickServices, setToggleQuickServices] = useState(false);

    if (!state) {
        return <div className="single-post">No post data available.</div>;
    }

    return (
        <div className="body">
            <Banner />
            <Header toggleMenu={toggleMenu} 
                setToggleMenu={setToggleMenu}
            />
            {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
                setToggleMenu={setToggleMenu}
            />}
            <div className="image2">
                <div id="newconnection">
                <p>News & Events</p>
                </div>
            </div>
            <div className="single-post">
                <div className="post-container">
                    {/* Title */}
                    <h1 className="post-title">{title}</h1>

                    {/* Date */}
                    <p className="post-date">{date}</p>

                    {/* First Image */}
                    {image1 && (
                        <div className="post-image">
                            <img src={image1} alt={title} />
                        </div>
                    )}

                    {/* Post Content */}
                    <div
                        className="post-details"
                        dangerouslySetInnerHTML={{ __html: details }}
                    ></div>

                    {/* Second Image */}
                    {image2 && (
                        <div className="post-image">
                            <img src={image2} alt={`${title} - second image`} />
                        </div>
                    )}
                </div>

                {/* Back Button */}
                <div className="back-button-container">
                    <button className="back-button" onClick={() => navigate(-1)}>
                        &larr; Back
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};