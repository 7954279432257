import "../styles/post_item.scss";
import { useNavigate } from "react-router-dom";

export default function PostItem (props) {

    const navigate = useNavigate();

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    return (
        <div className="post"> 
            <div className="blob">
                <img src={props.image1} alt="" />
            </div>
            <div className="detail">
                <h3>{props.title}</h3>
                <p>{props.date}</p>
                <p dangerouslySetInnerHTML={{ __html: truncateText(props.details, 125) }}></p>
                <button onClick={() =>
                        navigate(`/newsandevents/post/${props.id}`, { state: { ...props } })
                    }>
                    {`More >>`}
                </button>
            </div>
        </div>
    );
}