import { faEnvelope, faLocationPin, faMessage, faPhone, faShare, faUserCheck, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function QuickLinks (props) {
    return (
        <div className="website">
            <div className="pages">
                <p className="heads">Quick Links</p>
                <br />
                <a href="/" >Home</a>
                {/* <a href="/aboutus">About</a> */}
                <SpecificPages page = "About" items = {
                            {"Who we are" : "/aboutus", 
                            "Our Board" : "/aboutus/board", 
                            "Our Management" : "/aboutus/management", 
                            "Chair's Statement" : "/statements/chair", 
                            "MD's Statement" : "/statements/md" }}/>
                <a href="/services">Services</a>
                <a href="/tenders">Tenders</a>
                <SpecificPages page = "Media" items = {
                        {"News & Events" : "/newsandevents", 
                            // "Reports" : "/reports", 
                            // "Downloads & Publications" : "/downloads", 
                            "Gallery" : "/gallery", 
                            // "Blog" : "/blog" 
                        }
                    }/>
                <a href="/careers" >Careers</a>
                <a href="/contactus" >Contact Us</a>
                <a href="/admin" >Staff</a>
            </div> 
            <div className="reachus">
                <p className="heads">Reach Us</p>
                <br />
                <div className="contsloc">
                    <FontAwesomeIcon icon={faUserCheck} />
                    <a href="/contactus">Customer Service</a>
                </div>
                <br />
                <div className="contsloc">
                    <FontAwesomeIcon icon={faPhone} />
                    <p>+254700484034 | +254731554433</p>
                </div>
                <br />
                <div className="contsloc">
                    <FontAwesomeIcon icon={faShare} />
                    <p>PO. Box 823-00216, Githunguri Kenya</p>
                </div>
                <br />
                <div className="contsloc">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p>info@githunguriwater.co.ke</p>
                </div>
                <br />
                <div className="contsloc">
                    <FontAwesomeIcon icon={faLocationPin} />
                    <p>Githunguri Municipality, Kiambu, Kenya. - Next to Githunguri Level 5 Hospital, along Githunguri-Kambaa Road.</p>
                </div>
            </div>
        </div>
    )
}

const SpecificPages = (props) => {
    const [displayAll, setDisplayAll] = useState(false)

    return (
        <div className="spages"> 
            <p onClick={() => setDisplayAll(!displayAll)}>{props.page} { displayAll ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}<br /></p>
            {/* <br /> */}
            {displayAll && props.items && Object.entries(props.items).map(([item, value], index) => <><a href={value} className="menuItem">{item}</a> <br /></>)}
            {/* {props.items?.map((item, index) => {
                        <a href={item?.link} id={index}>{item?.name}</a>
            })} */}
        </div>
    )
}