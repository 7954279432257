import "../../styles/button.scss";

import PostItem from "../../components/post_item";
import cis from "../../assets/imgs/cis.png";
import gatitu from "../../assets/imgs/gatitumeeting.jpg";
import pe from "../../assets/imgs/publicparticipation.jpeg";
import waterinterruptiongatamaiyu from "../../assets/imgs/waterinterruptiongatamaiyu.jpg";
import waterrestoredgatamaiyu from "../../assets/imgs/waterrestoredgatamaiyu.jpg";

export default function PostItems (props) {

    return (
        <div className="posts">
              <PostItem id="1" image1={gatitu} title="Gititu Public Engagement" details="Happy New year! <br/>
              At Githunguri Water, we started the year on a high note by engaging with the residents of Gititu Village in Komothai Ward. In our collective effort to ensure accessible, reliable and safe water for all, we listened to the residents concerns regarding management of Gititu Water Project. <br/> <br/>
              Majority of the community members expressed their wish that Githunguri Water Company take over the management of the project which has been mismanaged for years denying consumers the much needed commodity. A caretaker committee was elected to oversee the transition which takes effect immediately. 
              <br/> <br/> In attendance was Githunguri Water MD Dr. Francis Kahuha, Komothai MCA Hon. Wanyutu Waweru, Area Chief, Current Treasurer and Members of Gititu Community Water Project among many other residents of Gititu." date="Sunday, 5th Jan 2025" />
              <PostItem id="2" image1={cis} title="Customer Identification Survey" details="We are currently conducting a Customer Identification Survey to enhance our services. By updating your details, you help us respond quickly to your needs and improve service reliability." date="Ongoing" />
              {/* <PostItem image={waterinterruptiongatamaiyu} title="Temporary Water Supply Interruption" details="We regret to inform residents of <b>Kamunyuini in Nyanduma Ward </b> that there is temporary water supply interruption due to the ongoing mau-mau road works affecting the entire <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano </b> and surrounding areas. <br/><br/>Our technical team is currently on-site working to restore the supply as quickly as possible.
We apologize for the inconvenience and appreciate your patience as we work to resolve this issue. <br/><br/>Further updates will be provided as soon as possible.
<br/>Thank you for your understanding." date="26 Oct 2024" /> */}
              <PostItem id="3"
                image1={waterrestoredgatamaiyu} 
                title="Water Supply Restored" 
                details="We are pleased to inform residents of <b>Kamunyuini in Nyanduma Ward</b> that the water supply has been restored following the resolution of issues caused by the ongoing mau-mau road works. <br/><br/>Our technical team has worked diligently to ensure water services are back to normal for all affected areas, including <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano</b>, and surrounding regions. <br/><br/>We appreciate your patience and understanding during this time and thank you for your continued support. 
              <br/>For further updates, please stay connected with us." 
                date="29 Oct 2024" 
              />
              <PostItem id="4" image1={pe} title="Thakwa Community Public Engagement" details="We will be conducting a public engagement at Thakwa. All stakeholders and our esteemed customers around Thakwa are invited to give their suggestions to help us respond quickly to your needs and improve service reliability." date="Sunday, 20th Oct 2024" />
              
            </div>
    );
}