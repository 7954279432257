import { useEffect, useState } from "react";
import "../styles/board.scss";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import personicon from "../assets/imgs/personicon.jpg"
import md from "../assets/imgs/board/MD.jpg";
import Godfrey from "../assets/imgs/board/Godfrey.png";
import WillyKimani from "../assets/imgs/board/WillyKimani.jpg";
import GNKangara from "../assets/imgs/board/GNKangara.png";
import Grace from "../assets/imgs/board/Grace.jpg";
import James from "../assets/imgs/board/James.jpg";
import Solomon from "../assets/imgs/board/Solomon.png";
import Jennifer from "../assets/imgs/board/JenniferMusyoki.png";

export default function Board (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>Our Board of Directors</p>
        </div>
      </div>
      <div className="board">
        <div className="persons">
          <Person name = "Godfrey Mwaura Kigochi" photo = {Godfrey} designation = "Chairman" academics={["Masters of Science in Project Management", "Bachelors of Arts in Public Administration"]}/>
        </div>
        <div className="persons">
          <Person name = "Solomon Wairiri" photo = {Solomon} designation = "Director" academics={["Masters in Theology", "Bachelor of Science in Agriculture"]} />
          <Person name = "Grace Nduta Gitau" photo = {Grace} designation = "Director" academics={["Diploma in IATA/FIATA", "Security Management Administration Police Training"]} />
          <Person name = "James Gichanga" photo = {James} designation = "Director" academics={["Bachelor of Science in Technology (Electrical &amp; Electronics Engineering"]} />
          <Person name = "William Kimani" photo = {WillyKimani} designation = "Chief Officer – Finance & Economic Planning Kiambu County" academics={["PhD in Business Administration", "Masters of Business Administration (Finance)", "Bachelor of Business Administration"]} />
          <Person name = "Kang'ara GN" photo = {GNKangara} designation = "Director" academics={[".", "Master of Laws", "Bachelor of Law", "_"]} />
          <Person name = "Jennifer Kanini Musyoki" photo = {Jennifer} designation = "Chief Officer – WEENR Kiambu County" academics={["Masters of Business Administration", "Bachelor of Science in Civil Engineering"]} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Person = (props) => {
  return (
    <div className="person">
      <div className="photo">
        <img src={props.photo} alt="" />
      </div>
      <div className="details">
        <h3>{props.name}</h3>
        <p className="designation">{props.designation}</p>
        {props.academics?.map((item, index) => <p className="academics" id={index}>{item}</p>)}
      </div>
    </div>
  )
} 